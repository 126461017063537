import { Container } from '@/app/container/Container';
import Image from 'next/image';
import React from 'react';

const Duvidas = ({ data }: any) => {
  return (
    <section
      className="text-center crm-primary-bg hidden md:block py-8"
      id="carousel_5183">
      <Container>
        <h2 className="text-white text-3xl mb-2">
          {data?.comparativo.subTitulo}
        </h2>
        <h3 className="text-white text-lg mb-6">{data?.comparativo.titulo}</h3>
        <div className="w-full overflow-x-auto">
          <table className="min-w-full bg-white">
            <colgroup>
              <col className="w-1/4" />
              <col className="w-1/4" />
              <col className="w-1/4" />
              <col className="w-1/4" />
            </colgroup>
            <thead className="bg-gray-100">
              <tr className="h-12">
                {data?.comparativo.compareTitulo.map(
                  (title: string, index: number) => (
                    <th
                      key={index}
                      className={`text-center p-2 border border-gray-400 ${
                        index === 0
                          ? 'bg-gray-100'
                          : index === 1
                            ? 'bg-green-500 font-extrabold'
                            : 'bg-yellow-500'
                      }`}>
                      {title}
                    </th>
                  )
                )}
              </tr>
            </thead>
            <tbody className="bg-gray-200">
              {data.beneficios.fotos.map((item: any, index2: number) => (
                <tr className="h-20" key={index2}>
                  <td className="text-left border border-gray-400 p-2">
                    {item.descricao}
                  </td>
                  <td className="border border-gray-400 p-2">
                    <Image
                      width={300}
                      height={300}
                      src="/pv-brasil/32282-f754bcd5.png"
                      alt="Icon 1"
                      className="mx-auto w-7 h-7"
                    />
                  </td>
                  <td className="border border-gray-400 p-2">
                    <Image
                      width={300}
                      height={300}
                      src="/pv-brasil/32282-ede1af29.png"
                      alt="Icon 2"
                      className="mx-auto w-7 h-7"
                    />
                  </td>
                  <td className="border border-gray-400 p-2">
                    <Image
                      width={300}
                      height={300}
                      src="/pv-brasil/32282-ede1af29.png"
                      alt="Icon 3"
                      className="mx-auto w-7 h-7"
                    />
                  </td>
                </tr>
              ))}
              {data.comparativo.compareDescricao.map(
                (item: any, index: number) => (
                  <tr className="h-12" key={index}>
                    <td className="text-left border border-gray-400 p-2">
                      {item.titulo}
                    </td>
                    {item.comparativo.map((item2: any, index2: number) => (
                      <td className="border border-gray-400 p-2" key={index2}>
                        {item2}
                      </td>
                    ))}
                  </tr>
                )
              )}
              <tr className="h-20">
                <td className="text-left border border-gray-400 bg-gray-200 p-2 font-bold">
                  Desconto
                </td>
                {data.produto.produtos.map((produto: any, index: number) => (
                  <td
                    className="border border-gray-400 p-2 font-bold"
                    key={index}>
                    {produto.desconto}
                  </td>
                ))}
              </tr>
              <tr className="h-20">
                <td className="text-left border border-gray-400 bg-gray-200 p-2">
                  Clique no botão para comprar
                </td>
                {data.produto.produtos.map((produto: any, index: number) => (
                  <td className="border border-gray-400 p-2" key={index}>
                    <a href={produto.checkout} rel="nofollow">
                      <button
                        className={`btn ${
                          index === 0
                            ? 'border-none bg-green-500 hover:bg-green-600'
                            : 'border-none bg-yellow-500 hover:bg-yellow-600'
                        }`}>
                        Comprar
                      </button>
                    </a>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </Container>
    </section>
  );
};

export default Duvidas;
