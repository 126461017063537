import { Container } from '@/app/container/Container';
import Image from 'next/image';
import Link from 'next/link';

interface Benefit {
  descricao: string;
}

interface HeroProps {
  data: {
    header: {
      titulo: string;
      foto: string;
    };
    beneficios: {
      fotos: Benefit[];
    };
  };
}

const Hero = ({ data }: HeroProps) => {
  return (
    <section className="bg-white py-10">
      <Container>
        <div className="flex flex-col md:flex-row items-center justify-between">
          {/* Left Column for Text and Benefits */}
          <div className="flex flex-col sm:items-start items-center justify-center w-full md:w-1/2">
            <h1 className="text-3xl font-bold crm-primary-text mb-4 text-center sm:text-left">
              {data.header.titulo}
            </h1>
            <ul className="text-lg text-gray-600 space-y-2">
              {data.beneficios.fotos.map((item, index) => (
                <li key={index} className="flex items-center space-x-2">
                  <svg
                    className="w-6 h-6 crm-primary-text flex-shrink-0"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    style={{ minHeight: '24px', minWidth: '24px' }}>
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span>{item.descricao}</span>
                </li>
              ))}
            </ul>
            <Link
              href="#produtos"
              className="inline-block mt-4 crm-primary-bg text-white font-bold py-2 px-6 rounded-full transition-colors duration-300 hover:brightness-90">
              SIM! QUERO EXPERIMENTAR AGORA!
            </Link>
          </div>

          {/* Right Column for Images */}
          <div className="w-full md:w-1/2 flex justify-center md:justify-end mt-4 md:mt-0">
            <Image
              src={data.header.foto}
              alt="Unidade do produto"
              width={300}
              height={300}
              className="transform hover:scale-105 transition-transform duration-300"
              unoptimized
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Hero;
