'use client';
import React, { useEffect, useState, useRef } from 'react';
import BackredirectTemplate from '../templates/backredirect/template';
import BackredirectLatamTemplate from '../templates/backredirect-latam/BackredirectLatamTemplate';
import VSLLatamTemplate from '../templates/vsl-latam/vsl-latam-template';
import PvLatamTemplate from '../templates/pv-latam/PvLatamTemplate';
import VslBrasilTemplate from '../templates/vsl-brasil/vsl-brasil-template';
import PvBrasilTemplate from '../templates/pv-brasil/PvBrasilTemplate';

interface ProxyProps {
  template: string;
  tipo: string;
  url: any;
  apiUrl: any;
}

interface TemplateData {
  tipo?: string;
}

export default function Proxy({ template, tipo, url, apiUrl }: ProxyProps) {
  const [data, setData] = useState<TemplateData | null>(null);
  const urlRef = useRef(url);

  useEffect(() => {
    let modifiedUrl = urlRef.current.replace(/^www\./, '');
    console.log('link sem o WWW:', modifiedUrl);

    if (template && tipo) {
      modifiedUrl += '/backredirect';
    } else if (template) {
      modifiedUrl += '/' + template;
    }

    console.log(`URL: ${apiUrl}/template?origin=${modifiedUrl}`);

    fetch(`${apiUrl}/template?origin=${modifiedUrl}`)
      .then((res) => res.json())
      .then((data) => {
        setData(data.object);
        console.log(data);
      })
      .catch((error) => {
        console.error('Erro ao buscar dados do template:', error);
      });

    // Capturar e armazenar os parâmetros UTM
    const urlParams = new URLSearchParams(window.location.search);
    const utmParams: { [key: string]: string } = {};
    urlParams.forEach((value, key) => {
      if (key.startsWith('?')) {
        utmParams[key] = value;
      }
    });
    localStorage.setItem('utmParams', JSON.stringify(utmParams));
  }, [template, tipo, apiUrl]);

  return (
    <>
      {data && (
        <>
          {(data as any).tipo === 'pv-brasil' && (
            <PvBrasilTemplate data={data} />
          )}

          {(data as any).tipo === 'vsl-brasil' && (
            <VslBrasilTemplate data={data} />
          )}

          {(data as any).tipo === 'backredirect' && (
            <BackredirectTemplate
              data={data}
              backredirect={template}
              tipo={tipo}
            />
          )}

          {(data as any).tipo === 'pv-latam' && <PvLatamTemplate data={data} />}

          {(data as any).tipo === 'vsl-latam' && (
            <VSLLatamTemplate data={data} />
          )}

          {(data as any).tipo === 'backredirect-latam' && (
            <BackredirectLatamTemplate
              data={data}
              backredirect={template}
              tipo={tipo}
            />
          )}
        </>
      )}
    </>
  );
}
